import useAuth from '@hooks/useAuth';
import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import Loader from '@widgets/Loader';
import {
  getTikTokOrganizationIDforConsent,
  hasValidProfileData,
} from '@utilities/common/user';
import useProfileDetails from './view/screens/Provider/Home/hooks/useProfileDetails';
import { useConsentCheck } from './view/screens/OnboardingForm/hooks/useConsent';
import useGetEmergencyContactsList from './view/screens/Provider/Home/hooks/useGetEmergencyContactsList';

export default function ConsentCheckRoutes() {
  const { user } = useAuth();
  const { data: profileData, isLoading: isLoadingProfile } = useProfileDetails({
    refetchOnWindowFocus: false,
  });
  const { data: ecList, isLoading: isLoadingEC } = useGetEmergencyContactsList({
    enabled: Boolean(profileData?.name),
  });

  const { data: consentStatus, isLoading: isCheckingConsent } = useConsentCheck(
    {
      email: user.emailOriginal,
      organisationId: getTikTokOrganizationIDforConsent(),
    },
    true,
  );
  const {
    currentUserData: { currentUserConfig },
  } = useCurrentUserContext();
  const hasUserConsented = () => consentStatus && !consentStatus.consent;


  const isConsentCheck = React.useMemo(() => {
    if (
      profileData &&
      consentStatus &&
      user.isEmployee &&
      currentUserConfig?.isCare &&
      (!hasUserConsented() || !hasValidProfileData(profileData))
    ) {
      return false;
    }
    return true;
  }, [profileData, consentStatus, currentUserConfig]);

  const hasEmpAddedEC = React.useMemo(() => {
    if (user.isCareNavigator || user.isResponder) {
      return true;
    }
    return ecList && ecList?.length >= 1;
  }, [user, ecList]);

  if (isCheckingConsent || isLoadingProfile || isLoadingEC) {
    return <Loader />;
  }
  return isConsentCheck && hasEmpAddedEC ? (

    <Outlet />
  ) : (
    <Navigate to="/onboarding" state={{ userExisting: isConsentCheck && Boolean(profileData?.name) }}/>
  );
}
