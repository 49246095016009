import * as React from 'react';
import useAuth from '@hooks/useAuth';
import useTracking from '@hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '@constants/analytics';
import { AnimatePresence, motion } from 'framer-motion';
import Analytics from '@services/Analytics';
import Footer from '@components/Footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { addItemToLocalStorage } from '@utilities/common/Storage';
import { StorageItems } from '@constants/App';
import ScreenerBanner from '../ScreenerBanner/ScreenerBanner';
import ChatFloatingButton from '../Chat/components/ChatFloatingButton';
import './Layout.scss';
import LayoutContext, {
  IFloatingChatWindowSettings,
  ILayoutContext,
} from '../../../shared/context/LayoutContext';
import usePaths from '../../../utilities/hooks/usePaths';
import Header from '../Header';
import FloatingChatWindowV2 from '../Chat/components/FloatingChatWindowV2';
import { useCoachHome } from '../Chat/utils/hooks/useHome';
import {
  routeExceptionsForSectionComponents,
  routeForFilterComponent,
  routesForPersonalInsightsBanner,
} from './constant/LayoutConstant';
import FilterSection from '../FilterSection/FilterSection';
import useScreenerBannerContext from '../ScreenerBanner/context/useScreenerBannerContext';

export interface ILayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: ILayoutProps) {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [firstPath] = usePaths();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isExceptionPath, setExceptionPath] = React.useState<boolean>(true); // * isExceptionPath is for rerendering layout if the current screen doesn't require navbar or header
  const { isLoggedIn, user } = useAuth();
  const { track } = useTracking();

  const [floatingChatWindowSettings, setFloatingChatWindowSettings] =
    React.useState<IFloatingChatWindowSettings>({
      show: false,
      initialProvider: undefined,
      viewMode: 'full-view',
    });
  const { show, initialProvider, viewMode } = floatingChatWindowSettings;

  const updateChatWindowSettings = (
    chatWindowSettings: Partial<IFloatingChatWindowSettings>,
  ) => {
    setFloatingChatWindowSettings((prev) => ({
      ...prev,
      ...chatWindowSettings,
    }));
  };
  const { data: homeData, refetch: refetchCoachHome } = useCoachHome({
    enabled: Boolean(isLoggedIn),
  });
  const contextValues = React.useMemo<ILayoutContext>(
    () => ({
      layoutRef: parentRef,
      updateChatWindowSettings,
      chatWindowVisible: show,
      reloadChat: refetchCoachHome,
    }),
    [show],
  );

  const showFilter = React.useMemo<boolean>(
    () => routeForFilterComponent.includes(firstPath) && !!user?.isEmployee,
    [firstPath],
  );

  const { currentMeeting, showPersonalInsightsBanner, updatePersonalInsightsBannerVisibility } = useScreenerBannerContext();

  const isMatch = /^\/personal-insights\/result\/[a-zA-Z0-9]+$/.test(pathname);
  const isScreenerPath = /^\/personal-insights\/screener$/.test(pathname);

  React.useEffect(() => {
    if (routeExceptionsForSectionComponents.includes(firstPath)) {
      setExceptionPath(true);
    } else {
      setExceptionPath(false);
    }
  }, [firstPath]);

  const handleClose = () => {
    if (!showPersonalInsightsBanner || !currentMeeting) return;

    const now = Date.now();
    const meetingTime = Number(currentMeeting.scheduledStartTime)  * 1000;
    const hoursLeft = (meetingTime - now) / (60 * 60 * 1000);

    addItemToLocalStorage(StorageItems.BANNER_DISMISSED, {
      dismissedAt: now,
      hideDuration: hoursLeft * 60 * 60 * 1000,
      meetingTime: currentMeeting.scheduledStartTime,
    });

    updatePersonalInsightsBannerVisibility(false);
  };

  const showChatButton = React.useMemo(
    () =>
      !isExceptionPath &&
      user?.isEmployee &&
      !!Object.keys(homeData || {}).length,
    [isExceptionPath, homeData],
  );
  React.useEffect(() => {
    // only for tracking event
    if (showChatButton && homeData) {
      const key = Object.keys(homeData || {})[0];

      updateChatWindowSettings({
        initialProvider: homeData[key],
        viewMode: window.innerWidth > 768 ? 'full-view' : 'chat-view',
      });
      track(EventNames.userChatScreen, {
        eventAction: EventActions.load,
        eventCategory: EventCategories.userChatScreen,
        userType: Analytics.getUserType(),
      });
    }
  }, [showChatButton, window.innerWidth]);

  return (
    <div className={`layout-container ${isScreenerPath ? 'screener-bg' : ''}`} ref={parentRef}>
      <LayoutContext.Provider value={contextValues}>
        {show && (
          <FloatingChatWindowV2
            initialProvider={initialProvider}
            viewMode={viewMode}
          />
        )}
        {!isExceptionPath && <Header />}
        { !!user?.isEmployee && 
        <AnimatePresence mode="wait">
          {(showPersonalInsightsBanner && routesForPersonalInsightsBanner.includes(firstPath)) || isMatch ? (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              style={{ overflow: 'hidden' }}
            >
              <ScreenerBanner
                text={
                  isMatch
                    ? 'Improve your wellbeing by sharing these results with your clinician today.'
                    : 'Get actionable insights into your mental wellbeing by attempting the 5 min screener!'
                }
                buttonTitle={isMatch ? 'Book now' : 'Start screener'}
                onButtonClick={() => navigate(isMatch ? '/' : '/personal-insights/screener')}
                onClose={!isMatch ? () => handleClose() : undefined}
              />
            </motion.div>
          ) : null}
        </AnimatePresence>
        }
        {showChatButton && <ChatFloatingButton coachHomeData={homeData} />}
        <div
          className={`main-content ${isExceptionPath ? 'no-page-margin' : ''}`}
        >
          {showFilter && <FilterSection />}
          {children}
        </div>
        <Footer/>
      </LayoutContext.Provider>
    </div>
  );
}

export default Layout;
